//Other variables

$marginSectionMargins: 4em;
$sidePadding: 2em;
$breakpointSmall: 28em; //448px
$breakpointMedium: 48em; //768px
$breakpointLarge: 75em; //1200px
$breakpoints: (small: $breakpointSmall, medium: $breakpointMedium, large: $breakpointLarge);

$defaultGutter: px-to-em(60px);

//Grid variables

$gridMain: (columns: 12, gutter: 1em);
$gridWork: (columns: 12, gutter: 6em);
// $gridTest: (columns: 3, gutter: 2rem);
$gridWork--medium: (columns: 12, gutter: 0, media: "(max-width: $breakpointMedium)");

//

// .gridTestContainer
// {
// 	@include grid-container
// }

// .gridTestCol2
// {
// 	@include grid-column(2, $gridTest);
// }

// .gridTestCol1
// {
// 	@include grid-column(1, $gridTest);
// }

.l-wrap {
    @include u-center;
    max-width: 1280px;
	padding-left: $sidePadding;
	padding-right: $sidePadding;
}


//Using padding and margins for sections because some sections will require the background to be colored.

.l-sectionMargin
{
	margin-top: $marginSectionMargins;
	margin-bottom: $marginSectionMargins;
}

.l-sectionPadding
{
	padding-top: $marginSectionMargins;
	padding-bottom: $marginSectionMargins;
}

.l-removeTopMargin
{
	margin-top: 0 !important; 
}

.l-removeBottomMargin
{
	margin-bottom: 0 !important; 
}


.l-sectionMargin--half
{
	margin-top: $marginSectionMargins/2;
	margin-bottom: $marginSectionMargins/2;
}


.l-sectionMargin--double
{
	margin-top: $marginSectionMargins*2;
	margin-bottom: $marginSectionMargins*2;
}

.l-fullCol {
    @include grid-column(12, $gridMain);
}

.l-contentWrap
{
	@include u-center;
	max-width: 600px;
}

.l-flexContainer
{
	display:flex;
}

.l-blockBottomMargin
{
	margin-bottom: px-to-em(60px);
}











