.c-navMenu__item--isActive
{
    @include t-bold;
}

.c-header {
    padding-top: 38px;
    padding-bottom: 38px;
    display: flex;
    align-items: center;

    .c-nav {
        margin-left: auto;

        //This right aligns the menu for a flexbox
        ul {
            list-style-type: none;

            li {
                a {
                    color: $bodyTextColor;
                    font-size: 20px;
                    &:hover {
                        color: $actionBlue--hover;
                    }
                }

                display: inline-block;
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    #menu-toggle {
        display: none;
    }

    .label-toggle {
        display: none;
    }

    @include media("<medium") {
        .c-nav {
            height: 0;

            //opacity to hide till checked
            opacity: 0;

            //Without this, it seems to be hidden under rest of the content
            position: relative;

            //Hidden till checked
            visibility: hidden;
            transition: all .5s ease;
            width: 100%;

            ul {
                text-align: left;
                padding-left:0;
                li {
                    display: block;
                    font-size: 1.2em;
                    padding:1em;
                }
            }
        }

        //If the menu toggle is checked show .c-nav
        #menu-toggle:checked ~ .c-nav {
            background-color: $darkBlue;
            opacity: 1;
            height: 100%;
            visibility: visible;
        }

        //Creates the hamburger and floats it to the right
        .label-toggle {
            background: linear-gradient(to bottom, #fff 0%, #fff 20%, transparent 20%, transparent 40%, #fff 40%, #fff 60%, transparent 60%, transparent 80%, #fff 80%, #fff 100%);

            //Gradient Hamburger
            cursor: pointer;
            display: block;
            float: right;
            height: 35px;
            margin-top: 1em;
            width: 35px;
        }

        & {
            display: block;
        }
    }
}