//Colors

$base : #F7F6F0;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);


$headerGrey: #494949;
$bodyGrey: #4A4A4A;
$bodyGrey__lighter: #878787;
$barGrey: rgba($bodyGrey, .1);
$borderGrey: rgba(151, 151,151, .40);
$dividerBlue: #A0BAF2;

$bg: #103EA8;
$bodyTextColor: #fff;
$bodyTextColor--opaque: rgba(255,255,255,.70);


$actionBlue: #1d5ae1;
$actionBlue--text:#45D7FE;
$actionBlue--hover: #00AEEF;
$actionBlue--active: lighten($actionBlue--hover,8%);


// $blue: #217FBE;
// $blue--hover: lighten($blue, 15%);

$darkBlue: #001ca8;
$twilightBlue: #0f327d;
$twilightBlue--75: rgba(15,50,125,.75);

$accordionBg: #184CBC;

$orange: #f7812f;

$secondary: rgba(255,255,255, .54);