* {
    box-sizing: border-box;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

html {}

body {
    @include t-bodyFont;
    color: $bodyTextColor;
    background-color: $bg;
}

body, html {}

a {
    color: $actionBlue--text;
    text-decoration: none;

    &:hover {
        color: $actionBlue--hover;
    }

    &:active {
        color: $actionBlue--active;
    }

}

p {
    
}

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
}

blockquote {
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  font-style: italic;
}
blockquote:before {
  color: #fff;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}