$gridHomeServices: (columns: 3, gutter: 2em);
$gridHomeServices--medium: (columns: 3, gutter: 0, media: "(max-width: #{$breakpointMedium})");


// $gridWhy: (columns: 12, gutter: 5em);

.l-homeServicesInner__services
{
	@include grid-container
	display: flex;
	flex-wrap: wrap; //media queries doesn't work without this
}

.l-homeService__item
{
	@include grid-column(1,$gridHomeServices);
	padding: px-to-em(32px);


	@include grid-media($gridHomeServices--medium)
	{
		@include grid-column (3);
		padding: px-to-em(12px);
		margin-bottom: px-to-em(16px);
	}

}
//Hero Section
.c-homeHero
{
	@include u-divider;
	margin-top: px-to-em(24px);
}

.c-homeheroInner__slogan
{
	@extend .t4;
	color: $bodyTextColor--opaque;
	margin-bottom: 60px;
}

.c-homeHeroInner__header
{
	margin-bottom: px-to-em(4px);
}

//Services Section

.c-homeServices
{
	background: $darkBlue;
	@include u-divider;
	text-align:center;
}

.c-homeServicesInner__services
{
	margin-bottom: px-to-em(40px);
}

.c-homeServiceItem__icon

{
	margin-bottom: px-to-em(24px);
}

.c-homeServiceInner__cta
{
	
}

//Why Section


.c-homeWhy
{
	@include u-divider;
}

// .l-whyInner
// {
// 	@include grid-container;
// 	@include grid-collapse;
// }

// .l-homeWhyInner__left
// {
// 	@include grid-column(6, $gridWhy);
// }

// .l-homeWhyInner__right
// {
// 	// @include grid-column(6, $gridWhy);
	
// }


.l-whyInner 
{
	@include media("<=medium") {
		flex-direction: column;
	}
}

.c-homeWhyInner_left
{
	width: 50%;
	margin-right: $defaultGutter;
	@include media("<=medium") {
		margin: 0;
		width: 100%;
		margin-bottom: 1em;
	}
	
}

.c-homeWhyInner_right
{
	width: 50%;
	@include media("<=medium") {
	width: 100%;
	}
}



//Icons

.o-homePageIcons {
	width: 125px;
	height: 125px;
	object-fit: contain;
}





