@function px-to-em($px, $base: 16px) {
    @return $px / $base * 1em;
}


@mixin u-center()
{
	margin: 0 auto;
  //Don't have text-align here because I don't want all the text centered for some items
}

@mixin u-removeLeftMargins
{
	margin-left: 0;
}

@mixin u-removeRightMargins
{
	margin-right: 0;
}

@mixin u-divider
{
	border-bottom: 2px solid $dividerBlue !important;
}

@mixin u-dividerTop
{
  border-top: 2px solid $dividerBlue !important;
}

@mixin u-button($bg, $borderColor:#fff, $borderWidth: 2px, $fontSize: 18px) {
  color: #fff;
  border-style: solid;
  border-width: $borderWidth;
  border-color: $borderColor;
  padding-top:px-to-em(12px);
  padding-bottom:px-to-em(12px);
  padding-left: px-to-em(42px);
  padding-right: px-to-em(42px);
  background: $bg;
  font-size: $fontSize;
  display: inline-block;
  &:hover {
    background:$actionBlue--hover;
    color: #fff;
    transition: all 0.3s ease;
  }
  &:active {
    color: #fff;
    background:$actionBlue--active;
  }
}


@mixin u-btnUnderline($textColor: #fff, $underlineColor: $orange, $underlineWidth: 2px)
{
  color: $textColor;
  text-decoration: none;
  border-bottom: $underlineWidth $underlineColor solid;
  &:hover
  {
    color: white;
    background: $actionBlue--hover;
    transition: all 0.3s ease;
  }
}


.u-center
{
	@include u-center;
	text-align:center;
}