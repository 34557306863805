$gridBlog: (columns: 1, gutter: 1em);



.l-blogWrap
{
	@include u-center;
	max-width: 700px;
}

.c-blogHeader
{
	margin-bottom: 2em;	
}

.c-blogHeader__title
{
	color: #fff;
}

.o-blogHeader__cover
{
	margin-top: 1em;
}


.c-blogPost{
	img
	{
		margin-top: 1em;
		margin-bottom: 1em;
	}
}

.c-blogPost__nextPost
{
	margin-right: 1em;
}

.c-blogPost__pagination
{
	li
	{
		border: 1px solid #fff !important;
	}
}