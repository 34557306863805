.btn
{
	@include u-button($actionBlue,#fff,2px, 18px);
}

.btn-secondary
{
	@include u-button(transparent,$actionBlue,2px, 18px);
}

.btn-nav
{
	color: #fff;
	border-style: solid;
	border-width: 2px;
	border-color: #fff;
	padding-top:px-to-em(8px);
	padding-bottom:px-to-em(8px);
	padding-left: px-to-em(16px);
	padding-right: px-to-em(16px);
	background: transparent;
	font-size: 20px;
	display: inline-block;
	&:hover {
	  background:$actionBlue--hover;
	  color: #fff !important;
	  transition: all 0.3s ease;
	}
	&:active {
	  color: #fff !important;
	  background:$actionBlue--active;
	}
}


.btn-underline
{
	@include u-btnUnderline(#fff,$orange, 2px);
}

.btn-underline--fatter
{
	border-bottom-width: 4px !important;
}