$gridServices: (columns: 2, gutter: 60px);
$gridServices--medium: (columns: 2, gutter: 3em, media: "(max-width: #{$breakpointMedium})"); //This allows you to use variables in the quotes

.l-serviceItemContainer
{
	@include grid-container;
	display: flex;
	@include grid-collapse ($gridServices); //You need to include the argument otherwise it defaults back to $neat-grid
	flex-wrap: wrap; //media queries doesn't work without this

}

.l-serviceItemContainer__item
{
	// display: flex;
	// flex-direction: column;
	@include grid-column(1,$gridServices);
	margin-bottom: px-to-em(42px);

	@include grid-media($gridServices--medium)
	{
		@include grid-column (2);
		margin-bottom: 2em;
	}
}

.c-serviceItem__box
{
	// flex-grow:1;
	border-top: $twilightBlue 10px solid;
	background-color: #1d5ae1;
	padding-top: px-to-em(32px);
	padding-bottom: px-to-em(32px);
	padding-left: px-to-em(48px);
	padding-right: px-to-em(48px);

	@include media("<medium") {
	padding-left: px-to-em(24px);
	padding-right: px-to-em(24px);
	}

}

.c-serviceItemBoxInner__content
{
	// margin-bottom: px-to-em(20px);
}

.c-serviceItemBox__accordion
{
	background-color: $accordionBg;
}

.c-serviceItemBoxAccordion__inner
{
	padding-top: px-to-em(16px);
	padding-bottom: px-to-em(16px);
	padding-left: px-to-em(48px);
	padding-right: px-to-em(48px);
}

.c-serviceItemBoxAccordion__btn
{
	transition: .4s;
	cursor: pointer;
	padding-top: px-to-em(16px);
	padding-bottom: px-to-em(16px);
	padding-left: px-to-em(32px);
	padding-right: px-to-em(48px);

	@include media("<medium") {
	padding-left: px-to-em(24px);
	padding-right: px-to-em(24px);
	}
	

	&:hover
	{
		background-color: $actionBlue--hover;
	}

	&:after {
    content: '\f067';
    font-family: FontAwesome;
    font-size: 18px;
    color: $orange !important;
    float: right;
    margin-left: 5px;
	}
}

.active:after {
    content: '\f068'; /* Unicode character for "plus" sign (+) */
    font-family: FontAwesome;
}


.c-serviceItemBoxAccordion__panel
{
	max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

}

.c-serviceItemBoxAccordtionPanel__subHeader

{
	padding-left: px-to-em(40px);
	padding-right: px-to-em(48px);
}


.c-serviceItemBoxAccordionPanel__item
{
	background-color: $twilightBlue--75;
	padding: px-to-em(16px);
	margin-top: px-to-em(16px);
	margin-bottom: px-to-em(16px);
	margin-left: px-to-em(40px);
	margin-right: px-to-em(48px);

	@include media("<medium") {
	margin-left: px-to-em(24px);
	margin-right: px-to-em(24px);
	}
}


.c-serviceItemBoxAccordionItem__service
{
	padding-bottom: 1em;
}
.o-serviceHeaderIcon
{
	width: 60px;
	height: 60px;
	margin-left: -8px; //shifting it so that it linesup with the other icons
	object-fit: contain;
}

.o-serviceItemIcon
{
	width: 40px;
	height: 40px;
	margin-right: px-to-em(8px);
	object-fit: contain;
}