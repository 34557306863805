$gridAboutUsBox: (columns:12, gutter:5em);


.c-aboutUsInner__box
{
	padding: px-to-em(50px);
	background-color: #1d5ae1;
}

.c-aboutUsInnerBox__inner
{
	// @include grid-container;
	// @include grid-collapse;
	display:flex;

	@include media("<=medium") {
		flex-direction: column;
	}
}

.c-aboutUsInnerBoxInner__left
{
	margin-right: $defaultGutter;

}


.c-aboutUsInnerBoxInner__right
{
	min-width: 350px;
}