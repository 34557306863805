.u-divider {
    @include u-divider;
}

.u-dividerTop
{
	@include u-dividerTop;
}

.u-removeTopMargin {
    margin-top: 0 !important;
}

.u-removeBottomMargin {
    margin-bottom: 0 !important;
}

.u-removeLeftPadding {
    padding-left: 0 !important;
}

.u-inline
{
	display: inline;
}

.u-turnWhite
{
	color: #fff !important;
}

.u-turnOrange
{
	color: $orange !important;
}

.c-pageTitle
{
	background-color: $darkBlue;
	padding-top: px-to-em(24px);
	padding-bottom: px-to-em(24px);
}