.c-headerWrapper {
    height: 130px;

}

.o-logo {
	display:inline-block;
    img {
        height: 53px;
    }
}