.c-footerWrapper
{
	height: 84px;
    border-top: 2px solid $dividerBlue !important;
}

.c-footer
{
	padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
}


.c-footer__left {}

.c-footer__right{
    margin-left: auto; //This right aligns the menu for a flexbox
    ul {
        li {
        	a{
        		color: #fff;
        		&:hover
        		{
        			color: $actionBlue--hover
        		}
        	}
            display: inline-block;
            margin-left: 16px;
            margin-right: 16px;
        }
    }
}


.c-footerCta
{
    
    background-color: $darkBlue;
    padding-top: px-to-em(40px);
    padding-bottom: px-to-em(40px);
}

.c-footerCta__inner
{

}